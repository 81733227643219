import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useInterval } from './hooks';
import { getPerfil } from './services/Perfil';
import Routes from './routes/routes';
import Header from './components/Header';
import Footer from './components/Footer';

import './App.scss';

const App = () => {
  const showFooter = useSelector((state) => state.menu.showFooter);
  const signed = useSelector((state) => state.user.signed);

  useInterval(async () => {
    if (signed) {
      const resp = await getPerfil();
      localStorage.setItem('@ranking', resp.perfil.ranking);
    }
  }, 60000);

  return (
    <BrowserRouter>
      <Header />
      <Routes />
      {showFooter && <Footer />}
    </BrowserRouter>
  );
};

export default App;
