import API from '../plugins/axios';

export function getPerfil() {
  return new Promise((resolve, reject) => {
    API.get('perfil/dados', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@token')}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeName(nome) {
  return new Promise((resolve, reject) => {
    API.post(
      'perfil/nome',
      {
        nome
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@token')}`
        }
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePassword(senha, confSenha) {
  return new Promise((resolve, reject) => {
    API.post(
      'perfil/senha',
      {
        senha,
        conf_senha: confSenha
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@token')}`
        }
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePhoto(data) {
  return new Promise((resolve, reject) => {
    API.post('perfil/imagem', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@token')}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
