import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom';
import Desafios from '../pages/Desafios';
import Perfil from '../pages/Perfil';
import Ranking from '../pages/Ranking';
import Premiacoes from '../pages/Premiacoes';
import DuvidasLogado from '../pages/DuvidasLogado';
import NotFound from '../pages/NotFound';
// import DesafioAgora from '../pages/DesafioAgora';
// import DesafioSemanal from '../pages/DesafioSemanal';
// import DesafioAgoraPartida from '../pages/DesafioAgoraPartida';

const AppRoutes = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('redirect')) {
      history.push(localStorage.getItem('redirect'));
      localStorage.removeItem('redirect');
    }
  }, [location]);

  return (
    <Switch>
      <Redirect from="/desafio-semanal" to="/ranking" />
      <Redirect from="/desafio-agora" to="/ranking" />
      <Redirect from="/desafio-agora/:codDesafio" to="/" />
      <Route path="/desafios" exact component={Desafios} />
      <Route path="/perfil" exact component={Perfil} />
      <Route path="/ranking" exact component={Ranking} />
      <Route path="/premiacoes" exact component={Premiacoes} />
      <Route path="/duvidas" exact component={DuvidasLogado} />
      <Route path="/" exact component={() => <Redirect to="/ranking" />} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
