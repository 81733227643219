import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ButtonDefault from './ButtonDefault';
import InputDefault from './InputDefault';
import logoChiesi from '../assets/images/logo_chiesi.svg';

import { changePassword } from '../services/Perfil';

import '../assets/scss/components/ModalRedefinirSenha.scss';

const ModalRedefinirSenha = ({
  show,
  handleClose,
  showSuccess,
  setShowMessage
}) => {
  // const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const [erroConfirma, setErroConfirma] = useState(false);
  const history = useHistory();

  const checkSenha = () => {
    if (senha === confirmaSenha) return true;
    return false;
  };

  const redefinirSenha = async (event) => {
    event.preventDefault();
    try {
      if (checkSenha()) {
        const resp = await changePassword(senha, confirmaSenha);
        handleClose();
        showSuccess();
        setSenha('');
        setConfirmaSenha('');
        setShowMessage(resp.message);
        setErroConfirma(false);
      } else {
        setErroConfirma(true);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        history.push('/');
      } else if (error.response.status === 404) {
        handleClose();
        showSuccess(true);
      }
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-redefinir"
      backdropClassName="backdrop-redefinir"
    >
      <Modal.Header>
        <div className="moda-header-close">
          <span onClick={handleClose}>×</span>
        </div>
      </Modal.Header>
      <form onSubmit={(e) => redefinirSenha(e)}>
        <Modal.Body>
          <img src={logoChiesi} alt="Chiesi" className="modal-body-img-logo" />
          <div className="modal-body-title">
            <h4>Redefinir senha</h4>
          </div>
          <div className="modal-body-input">
            {/* <InputDefault
            type="email"
            name="email"
            value={email}
            labelPosition="top"
            label="Confirme seu e-mail:"
            onchange={(value) => setEmail(value)}
          /> */}
            <InputDefault
              name="senha"
              value={senha}
              type="password"
              label="Nova senha:"
              labelPosition="top"
              onchange={(value) => setSenha(value)}
            />
            <InputDefault
              name="senha"
              type="password"
              labelPosition="top"
              value={confirmaSenha}
              label="Confirme sua nova senha:"
              onchange={(value) => setConfirmaSenha(value)}
              erro={erroConfirma}
              erroMsg="A senha e a confirmação de senha devem ser iguais."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonDefault
            bgColor="#3d3d46"
            textColor="#FFFFFF"
            text="Redefinir senha"
            classes="modal-footer-btn"
            submit
            disabled={!(senha !== '' && confirmaSenha !== '')}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ModalRedefinirSenha.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  showSuccess: PropTypes.func,
  setShowMessage: PropTypes.func
};

ModalRedefinirSenha.defaultProps = {
  show: false,
  handleClose: () => {},
  showSuccess: () => {},
  setShowMessage: () => {}
};

export default ModalRedefinirSenha;
