import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import Sobre from '../pages/Sobre';
import DuvidasDeslogado from '../pages/DuvidasDeslogado';
import RecuperarSenha from '../pages/RecuperarSenha';
import NotFound from '../pages/NotFound';

const AuthRoutes = () => {
  const renderHome = () => {
    localStorage.setItem('redirect', window.location.pathname);
    return <Redirect to="/" />;
  };

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/sobre" exact component={Sobre} />
      <Route path="/duvidas" exact component={DuvidasDeslogado} />
      <Route path="/recuperar-senha/:token" exact component={RecuperarSenha} />
      <Route path="/desafio-semanal" exact component={renderHome} />
      <Route path="/desafio-agora" component={renderHome} />
      <Route path="/perfil" exact component={renderHome} />
      <Route path="/premiacoes" exact component={renderHome} />
      <Route path="/desafios" exact component={renderHome} />
      <Route path="/ranking" exact component={renderHome} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AuthRoutes;
