import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { SvgIcon } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import iconUser from '../assets/images/icon_user.svg';
import iconMenu from '../assets/images/icon_menu.svg';
import iconClose from '../assets/images/icon_close.svg';
import iconBlue from '../assets/images/icon_blue2.svg';
import iconGoout from '../assets/images/icon_goout.svg';
import iconAwards from '../assets/images/icon_awards.svg';
import iconChallenges from '../assets/images/icon_challenges.svg';
import iconProfile from '../assets/images/icon_profile.svg';

import logoVoceNoCentro from '../assets/images/logo-branca-GRANDE.svg';

import '../assets/scss/components/Header.scss';
import { changeSigned, showModalCadastro } from '../features/userSlice';
import { setOpened } from '../features/menuSlice';

const classes = {
  '/duvidas': 'in-bg-white in-bd-blue in-bt-orange',
  '/desafio-agora': 'in-bg-pink in-bd-purple in-bt-purple',
  '/desafio-semanal': 'in-bg-pink in-bd-purple in-bt-gray',
  '/sobre': 'in-bg-pink in-bd-purple in-bt-gray',
  '/ranking': 'in-bg-white in-bd-green in-bt-gray',
  '/perfil': 'in-bg-white in-bd-green in-bt-gray',
  '/desafios': 'in-bg-white in-bd-green in-bt-green',
  '/premiacoes': 'in-bg-white in-bd-brown in-bt-brown'
};

const Header = () => {
  const [menuDropdownOpened, setMenuDropdownOpened] = useState(false);
  const [classHeader, setClassHeader] = useState('');
  const signed = useSelector((state) => state.user.signed);
  const apelido = useSelector((state) => state.user.userData.apelido);
  const fotoPerfil = useSelector((state) => state.user.photo);
  const menuOpened = useSelector((state) => state.menu.menuOpened);
  const dispatch = useDispatch();
  const menuDropdown = useRef();
  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  const location = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    setMenuDropdownOpened(false);
    dispatch(changeSigned(!!localStorage.getItem('@user')));
    history.push('/');
  };

  const handleMenuDropdown = (e) => {
    if (menuDropdown.current !== null && menuDropdown.current !== undefined) {
      if (!menuDropdown.current.contains(e.target)) {
        setMenuDropdownOpened(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuDropdown);
    return () => {
      document.removeEventListener('mousedown', handleMenuDropdown);
    };
  }, []);

  useEffect(() => {
    setClassHeader(classes[location.pathname]);
    setMenuDropdownOpened(false);
  }, [location]);

  return (
    <Navbar
      id="in-hd"
      className={`${signed ? 'logado' : 'deslogado'} ${classHeader}`}
    >
      <div className="in-hd-ctn">
        <div className="in-hd-ctn-grid">
          <div className="in-hd-ctn-grid-logo">
            <Link to="/">
              <img src={logoVoceNoCentro} alt="Você no Centro de Tudo 3.0" />
            </Link>
          </div>
          <div className={`in-hd-ctn-grid-nav ${menuOpened ? 'opened' : ''}`}>
            {((isMobile && width <= 1024) || width <= 1024) && (
              <div className="in-hd-ctn-grid-nav-mb">
                <p>Menu</p>
                <button
                  type="button"
                  onClick={() => dispatch(setOpened(!menuOpened))}
                >
                  <img src={iconClose} alt={iconClose} />
                </button>
              </div>
            )}
            <div
              className={`in-hd-ctn-grid-nav-wd ${
                signed ? 'in-hd-ctn-grid-nav-wd-logado' : ''
              }`}
            >
              {signed ? (
                <>
                  {/* <NavLink
                    to="/desafio-semanal"
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Desafio semanal
                  </NavLink>
                  <NavLink
                    to="/desafio-agora"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Desafio agora
                  </NavLink> */}
                  <NavLink
                    to="/ranking"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Ranking
                  </NavLink>
                  <NavLink
                    to="/premiacoes"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Premiações
                  </NavLink>
                  <NavLink
                    to="/duvidas"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Dúvidas
                  </NavLink>
                  {((isMobile && width <= 1024) || width <= 1024) && (
                    <>
                      <NavLink
                        to="/perfil"
                        exact
                        className="in-hd-ctn-grid-nav-link"
                        activeClassName="in-hd-ctn-grid-nav-active"
                      >
                        Perfil
                      </NavLink>
                      <NavLink
                        to="/desafios"
                        exact
                        className="in-hd-ctn-grid-nav-link"
                        activeClassName="in-hd-ctn-grid-nav-active"
                      >
                        Desafios
                      </NavLink>
                      <span
                        className="in-hd-ctn-grid-nav-link"
                        onClick={handleLogout}
                      >
                        Sair
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <NavLink
                    to="/sobre"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Sobre
                  </NavLink>
                  <NavLink
                    to="/duvidas"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Dúvidas
                  </NavLink>
                  <div className="in-hd-ctn-grid-nav-icon">
                    <img src={iconBlue} alt={iconBlue} />
                  </div>
                  <NavLink
                    to="/"
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Entrar
                  </NavLink>
                  <NavLink
                    to="/"
                    onClick={() => dispatch(showModalCadastro(true))}
                    exact
                    className="in-hd-ctn-grid-nav-link"
                    activeClassName="in-hd-ctn-grid-nav-active"
                  >
                    Cadastre-se
                  </NavLink>
                </>
              )}
            </div>
          </div>
          {signed && (
            <>
              {((!isMobile && width > 1024) || (isMobile && width > 1024)) && (
                <div
                  className="in-hd-ctn-grid-user"
                  onClick={() => setMenuDropdownOpened(!menuDropdownOpened)}
                >
                  <p className="in-hd-ctn-grid-user-name">
                    Olá, <span>{apelido?.split(' ')[0]}</span>{' '}
                    <SvgIcon component={ArrowDropDown} />
                  </p>
                  <div className="in-hd-ctn-grid-user-photo">
                    {fotoPerfil !== null ? (
                      <img src={fotoPerfil} alt={fotoPerfil} />
                    ) : (
                      <img src={iconUser} alt={iconUser} />
                    )}
                  </div>
                  {menuDropdownOpened && (
                    <div
                      ref={menuDropdown}
                      className="in-hd-ctn-grid-user-dropdown"
                    >
                      <div className="in-hd-ctn-grid-user-dropdown-sup">
                        <p>
                          <span>{apelido?.split(' ')[0]}</span>{' '}
                          <SvgIcon
                            onClick={() =>
                              setMenuDropdownOpened(!menuDropdownOpened)
                            }
                            component={ArrowDropDown}
                          />
                        </p>
                        <div className="in-hd-ctn-grid-user-dropdown-sup-photo">
                          {fotoPerfil !== null ? (
                            <img src={fotoPerfil} alt={fotoPerfil} />
                          ) : (
                            <img src={iconUser} alt={iconUser} />
                          )}
                        </div>
                      </div>
                      <div className="in-hd-ctn-grid-user-dropdown-sub">
                        <ul>
                          <Link to="/perfil">
                            <li>
                              <img src={iconProfile} alt={iconProfile} />
                              <span>Perfil</span>
                            </li>
                          </Link>
                          <Link to="/desafios">
                            <li>
                              <img src={iconChallenges} alt={iconChallenges} />
                              <span>Desafios</span>
                            </li>
                          </Link>
                          <Link to="/ranking">
                            <li>
                              <p>{localStorage.getItem('@ranking')}º</p>
                              <span>Ranking</span>
                            </li>
                          </Link>
                          <Link to="/premiacoes">
                            <li>
                              <img src={iconAwards} alt={iconAwards} />
                              <span>Premiações</span>
                            </li>
                          </Link>
                          <span onClick={handleLogout}>
                            <li>
                              <img src={iconGoout} alt={iconGoout} />
                              <span>Sair</span>
                            </li>
                          </span>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {((isMobile && width <= 1024) || width <= 1024) && (
            <div className="in-hd-ctn-grid-menu">
              <button
                type="button"
                onClick={() => dispatch(setOpened(!menuOpened))}
              >
                <img src={iconMenu} alt={iconMenu} />
              </button>
            </div>
          )}
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
