import React from 'react';
import PropTypes from 'prop-types';

import '../assets/scss/components/InputDefault.scss';

const InputDefault = ({
  label,
  type,
  onchange,
  value,
  className,
  name,
  labelPosition,
  readonly,
  focusout,
  erro,
  erroMsg
}) => {
  return (
    <div
      className={`input-default label-${labelPosition} ${erro ? 'erro' : ''}`}
    >
      <div>
        <input
          type={type}
          value={value}
          name={name}
          placeholder={label}
          className={className}
          onChange={(e) => onchange(e.target.value)}
          onBlur={focusout}
          readOnly={readonly}
        />
        {erro && erroMsg !== '' && (
          <p className="errorMsg">
            {' '}
            <small>{erroMsg}</small>
          </p>
        )}

        <label color="#fff">{label}</label>
      </div>
    </div>
  );
};

InputDefault.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  readonly: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onchange: PropTypes.func,
  focusout: PropTypes.func,
  labelPosition: PropTypes.string,
  erro: PropTypes.bool,
  erroMsg: PropTypes.string
};

InputDefault.defaultProps = {
  label: '',
  type: 'text',
  value: '',
  readonly: false,
  className: '',
  name: '',
  labelPosition: 'left' || 'top' || 'rigth',
  onchange: () => {},
  focusout: () => {},
  erro: false,
  erroMsg: ''
};

export default InputDefault;
