import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { changeSigned, setUserData } from '../features/userSlice';
import { setOpened, setFooter } from '../features/menuSlice';
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const Routes = () => {
  const dispatch = useDispatch();
  const signed = useSelector((state) => state.user.signed);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(setOpened(false));
    if (!signed && location.pathname === '/') {
      dispatch(setFooter(false));
    } else {
      dispatch(setFooter(true));
    }
    dispatch(changeSigned(!!localStorage.getItem('@user')));
    if (localStorage.getItem('@user')) {
      dispatch(setUserData(JSON.parse(localStorage.getItem('@user'))));
    }
  }, [location]);
  return signed ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;
