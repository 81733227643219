import React from 'react';
import { useSelector } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

import logoChiesi from '../assets/images/logoCHIESI-wt.svg';
import logoVoceNoCentro from '../assets/images/logo-branca-p.svg';
import iconGrey from '../assets/images/icon_grey.png';

import '../assets/scss/components/Footer.scss';

const Header = () => {
  const signed = useSelector((state) => state.user.signed);

  return (
    <Navbar id="in-ft" className={`${signed ? 'logado' : 'deslogado'}`}>
      <div className="in-ft-ctn">
        <div className="in-ft-ctn-grid">
          <div className="in-ft-ctn-grid-logo">
            <Link to="/">
              <img src={logoVoceNoCentro} alt="Você no Centro de Tudo 3.0" />
            </Link>
          </div>
          <div className="in-ft-ctn-grid-nav">
            <div className="in-ft-ctn-grid-nav-wd">
              {signed ? (
                <>
                  {/*                 
                  <NavLink
                    to="/desafio-semanal"
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Desafio semanal
                  </NavLink>
                  <NavLink
                    to="/desafio-agora"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Desafio agora
                  </NavLink> */}
                  <NavLink
                    to="/ranking"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Ranking
                  </NavLink>
                  <NavLink
                    to="/premiacoes"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Premiações
                  </NavLink>
                  <NavLink
                    to="/duvidas"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Dúvidas
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/sobre"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Sobre
                  </NavLink>
                  <NavLink
                    to="/duvidas"
                    exact
                    className="in-ft-ctn-grid-nav-link"
                  >
                    Dúvidas
                  </NavLink>

                  <img src={iconGrey} alt="icone cinza" />

                  <NavLink to="/" exact className="in-ft-ctn-grid-nav-link">
                    Entrar
                  </NavLink>
                  <NavLink to="/" exact className="in-ft-ctn-grid-nav-link">
                    Cadastre-se
                  </NavLink>
                </>
              )}
            </div>
          </div>
          <div className="in-ft-ctn-grid-logo2">
            <Link to="/">
              <img src={logoChiesi} alt="Chiesi" />
            </Link>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
