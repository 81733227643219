import React from 'react';
import PropTypes from 'prop-types';

import '../assets/scss/components/ButtonDefault.scss';

const ButtonDefault = ({
  text,
  bgColor,
  textColor,
  classes,
  onclick,
  disabled,
  submit
}) => {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={() => onclick()}
      className={`button-default ${classes}`}
      style={{ background: bgColor, color: textColor }}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

ButtonDefault.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  classes: PropTypes.string,
  submit: PropTypes.bool,
  onclick: PropTypes.func,
  disabled: PropTypes.bool
};

ButtonDefault.defaultProps = {
  text: '',
  bgColor: '',
  textColor: '',
  classes: '',
  submit: false,
  onclick: () => {},
  disabled: false
};

export default ButtonDefault;
