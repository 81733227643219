/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setPhoto } from '../features/userSlice';
import { getPerfil, changeName, changePhoto } from '../services/Perfil';

import ModalRedefinirSenha from '../components/ModalRedefinirSenha';
import ButtonDefault from '../components/ButtonDefault';
import ModalSucesso from '../components/ModalSucesso';

import iconUser from '../assets/images/icon_user.svg';
import iconScore from '../assets/images/score2.svg';
import iconDiamond from '../assets/images/icon_diamond.svg';

import '../assets/scss/Perfil.scss';

const Perfil = () => {
  const userData = JSON.parse(localStorage.getItem('@user'));
  const [apelido, setApelido] = useState(userData.apelido);
  const [dadosPerfil, setDadosPerfil] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [success, setModalSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const fotoPerfil = useSelector((state) => state.user.photo);

  const actions = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Perfil | Você no Centro de Tudo 3.0';
  }, []);

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleMenuActions = (e) => {
    if (actions.current !== null && actions.current !== undefined) {
      if (e.target.id !== 'plus') {
        if (!actions.current.contains(e.target)) {
          setOpenActions(false);
        }
      }
    }
  };

  const showSuccess = (error = false) => {
    setErr(error);
    setModalSuccess(true);
  };

  const editaApelido = async () => {
    try {
      const resp = await changeName(apelido);
      userData.apelido = apelido;
      localStorage.setItem('@user', JSON.stringify(userData));
      setErr(false);
      setModalMessage(resp.message);
      setModalSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        history.push('/');
      } else if (error.response.status === 404) {
        setErr(true);
        setModalSuccess(true);
      }
    }
  };

  const pickImage = () => {
    document.getElementById('inputFile').click();
  };
  const getImage = async (e) => {
    try {
      const form = new FormData();
      form.append('imagem', e.target.files[0], e.target.files[0].name);
      const resp = await changePhoto(form);
      localStorage.setItem('@imagem_perfil', resp.imagem_perfil);
      dispatch(setPhoto(resp.imagem_perfil));
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        history.push('/');
      }
    }
  };

  const removeImagem = async () => {
    try {
      await changePhoto(null);
      localStorage.setItem('@imagem_perfil', null);
      dispatch(setPhoto(null));
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        history.push('/');
      }
    }
  };

  useEffect(() => {
    let unmounted = false;
    document.addEventListener('mousedown', handleMenuActions);
    const loadDadosPerfil = async () => {
      try {
        const resp = await getPerfil();
        if (!unmounted) {
          setDadosPerfil(resp);
        }
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          history.push('/');
        }
      }
    };
    loadDadosPerfil();
    return () => {
      unmounted = true;
      document.removeEventListener('mousedown', handleMenuActions);
    };
  }, []);

  return (
    <section id="in-pf">
      <ModalSucesso
        show={success}
        message={modalMessage}
        handleClose={() => setModalSuccess(!success)}
        error={err}
      />
      <ModalRedefinirSenha
        show={showModal}
        handleClose={handleClose}
        showSuccess={showSuccess}
        setShowMessage={setModalMessage}
      />

      <div className="in-pf-ctn">
        <div className="in-pf-ctn-grid">
          <div className="in-pf-ctn-grid-lf">
            <div className="in-pf-ctn-grid-lf-sup">
              <div className="in-pf-ctn-grid-lf-sup-avatar">
                {fotoPerfil !== null ? (
                  <img src={fotoPerfil} alt={fotoPerfil} />
                ) : (
                  <img src={iconUser} alt={iconUser} />
                )}
              </div>
              <div className="in-pf-ctn-grid-lf-sup-dropdown">
                <div className="in-pf-ctn-grid-lf-sup-dropdow-icon">
                  <span id="plus" onClick={() => setOpenActions(!openActions)}>
                    +
                  </span>
                </div>
                {openActions && (
                  <div
                    className="in-pf-ctn-grid-lf-sup-dropdow-actions"
                    ref={actions}
                  >
                    <ul>
                      <li onClick={removeImagem}>
                        <span>Remover</span>
                      </li>
                      <li onClick={pickImage}>
                        <input type="file" id="inputFile" onChange={getImage} />
                        <span htmlFor="inputFile">Alterar</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="in-pf-ctn-grid-lf-sub">
              <h3>Aviso!</h3>
              <p>
                Ao selecionar sua foto de perfil não <br /> utilize imagens com
                conteúdos que <br /> causam alguma polêmica, como:
              </p>
              <ul>
                <li>Posicionamentos ou apoio político;</li>
                <li>Times de futebol ou de outros esportes;</li>
                <li>Conteúdos agressivos ou chocantes;</li>
                <li>Posicionamentos religiosos;</li>
                <li>
                  Qualquer tipo de preconceito <br /> ou discriminação.
                </li>
              </ul>
            </div>
          </div>
          <div className="in-pf-ctn-grid-rt">
            <div className="in-pf-ctn-grid-rt-sup">
              <div className="in-pf-ctn-grid-rt-sup-info">
                <p>{userData.nome}</p>
                <ButtonDefault
                  text="Salvar"
                  bgColor="#82B477"
                  textColor="#FFFFFF"
                  classes="in-pf-ctn-grid-rt-sup-edit-btn"
                  disabled={apelido === userData.apelido}
                  onclick={editaApelido}
                />
                <ButtonDefault
                  bgColor="#3c3c46"
                  textColor="#FFFFFF"
                  text="Redefinir Senha"
                  classes="in-pf-ctn-grid-rt-sup-info-btn"
                  onclick={() => setShowModal(true)}
                />
              </div>
              <div className="in-pf-ctn-grid-rt-sup-edit">
                <input
                  type="text"
                  value={apelido}
                  onChange={(e) => setApelido(e.target.value)}
                />
              </div>
            </div>
            <div className="in-pf-ctn-grid-rt-sub">
              <div className="in-pf-ctn-grid-rt-sub-item">
                <div className="circle-style2">
                  <img src={iconScore} alt="Classificação" />
                </div>
                <h1>Classificação</h1>
                <h2>{dadosPerfil.perfil?.ranking}º</h2>
              </div>
              <div className="in-pf-ctn-grid-rt-sub-item">
                <div className="circle-style">
                  <img src={iconDiamond} alt={iconDiamond} />
                </div>
                <h1>Diamantes</h1>
                <h2>{dadosPerfil.perfil?.diamantes}</h2>
              </div>
              <div className="in-pf-ctn-grid-rt-sub-item">
                <div className="circle-style2">
                  <img src={iconScore} alt="pontos" />
                </div>
                <h1>Pontos</h1>
                <h2>{dadosPerfil.perfil?.pontos}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Perfil;
