/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonDefault from './ButtonDefault';
import InputDefault from './InputDefault';

import { busca, insere } from '../services/User';

import '../assets/scss/components/ModalCadastro.scss';

import logoChiesi from '../assets/images/logo_chiesi.svg';

const ModalDefault = ({
  show,
  handleClose,
  handleModalSuccess,
  showRecuperar
}) => {
  const [codUsuario, setCodUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [area, setArea] = useState('');
  const [publico, setPublico] = useState('');
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmar, setConfirmar] = useState('');
  const [erroConfirma, setErroConfirma] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [erroCadastro, setErroCadastro] = useState(false);
  const [openedinfo, setOpenedInfo] = useState(false);
  const [erroMsgEmail, setErroMsgEmail] = useState('');

  const handleFocus = async () => {
    setNome('');
    try {
      const resp = await busca(email);
      setErroEmail(false);
      setNome(resp.nome);
      setArea(resp.area);
      setPublico(resp.publico);
      setCodUsuario(resp.cod_usuario);
    } catch (error) {
      setErroMsgEmail(error.response.data.message);
      setErroEmail(true);
    }
  };

  const confirmaSenha = () => {
    if (senha === confirmar) return true;
    return false;
  };

  const limpaCampos = () => {
    setCodUsuario('');
    setEmail('');
    setArea('');
    setPublico('');
    setNome('');
    setApelido('');
    setSenha('');
    setConfirmar('');
    setErroConfirma(false);
    setErroEmail(false);
    setErroCadastro(false);
    setErroMsgEmail('');
  };

  const handleRecuperar = () => {
    handleClose();
    showRecuperar();
  };

  const submit = async () => {
    try {
      const data = {
        cod_usuario: codUsuario,
        senha,
        conf_senha: confirmar,
        apelido: apelido !== '' ? apelido : nome
      };
      if (confirmaSenha()) {
        await insere(data);
        limpaCampos();
        handleClose();
        handleModalSuccess();
      } else {
        setErroConfirma(true);
      }
    } catch (error) {
      setErroCadastro(true);
    }
  };

  const allFilled = () => {
    if (
      email !== '' &&
      area !== '' &&
      publico !== '' &&
      nome !== '' &&
      // apelido !== '' &&
      senha !== '' &&
      confirmar !== ''
    )
      return true;
    return false;
  };

  return (
    <Modal centered show={show} className="modal-cadastro">
      <Modal.Header>
        <img src={logoChiesi} alt="Chiese" />
        <div
          onClick={() => setOpenedInfo(!openedinfo)}
          className="modal-header-info"
        >
          Informações
        </div>

        <div onClick={handleClose} className="icon-close">
          ×
        </div>
      </Modal.Header>
      <div className={`modal-cadastro-info ${openedinfo ? 'opened' : ''}`}>
        <div className="modal-cadastro-txt">
          <p>
            Recomendamos que utilize a mesma senha do Office 365 em seu
            cadastro.
          </p>
        </div>
      </div>
      <Modal.Body>
        <div className="modal-body-input">
          <InputDefault
            type="email"
            label="E-mail"
            name="email"
            value={email}
            onchange={(value) => setEmail(value)}
            focusout={handleFocus}
            erro={erroEmail}
          />
          <InputDefault
            type="text"
            label="Área"
            name="area"
            value={area}
            onchange={(value) => setArea(value)}
            readonly
          />
          <InputDefault
            type="text"
            label="Público"
            name="publico"
            value={publico}
            onchange={(value) => setPublico(value)}
            readonly
          />
          <InputDefault
            type="text"
            label="Nome"
            name="nome"
            value={nome}
            onchange={(value) => setNome(value)}
            readonly
          />
          <InputDefault
            type="text"
            label="Apelido"
            name="Apelido"
            value={apelido}
            onchange={(value) => setApelido(value)}
          />
          <InputDefault
            type="password"
            label="Senha"
            name="senha"
            value={senha}
            onchange={(value) => setSenha(value)}
          />
          <InputDefault
            type="password"
            label="Confirmar"
            name="confirmar"
            value={confirmar}
            onchange={(value) => setConfirmar(value)}
            erro={erroConfirma}
            erroMsg="A senha e a confirmação de senha devem ser iguais."
          />
        </div>
        {erroEmail && (
          <p>
            <div
              className="erroModal"
              dangerouslySetInnerHTML={{
                __html: erroMsgEmail
              }}
            />
          </p>
        )}
        <br />
        {erroCadastro && (
          <p className="erroModal">
            Oops! Houve um problema, tente novamente mais tarde!
          </p>
        )}
        <ButtonDefault
          bgColor="#b80164"
          text="Cadastre-se"
          textColor="#FFFFFF"
          onclick={submit}
          classes="modal-body-btn"
          disabled={!allFilled()}
        />
        <ButtonDefault
          bgColor="#41404a"
          textColor="#fff"
          onclick={handleRecuperar}
          classes="modal-footer-btn"
          text="Recuperar senha"
        />
      </Modal.Body>
    </Modal>
  );
};

ModalDefault.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  showRecuperar: PropTypes.func
};

ModalDefault.defaultProps = {
  show: false,
  handleClose: () => {},
  handleModalSuccess: () => {},
  showRecuperar: () => {}
};

export default ModalDefault;
