import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonDefault from './ButtonDefault';

import '../assets/scss/components/ModalSucesso.scss';

const ModalSucesso = ({ show, handleClose, error, message }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className={`modal-sucesso ${error ? 'error' : ''}`}
      backdropClassName="backdrop-sucesso"
    >
      <Modal.Header>
        <div className="moda-header-close">
          <span onClick={handleClose}>×</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-title">
          <h4>{error ? 'Aviso' : 'Aviso'}</h4>
        </div>
        {error && (
          <>
            {message === '' ? (
              <p className="modal-body-text">
                Oops! Houve um problema, tente novamente mais tarde!
              </p>
            ) : (
              <p className="modal-body-text">{message}</p>
            )}
          </>
        )}
        {!error && (
          <>
            {message === '' ? (
              <p className="modal-body-text">Sucesso!</p>
            ) : (
              <p className="modal-body-text">{message}</p>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefault
          text="Fechar"
          textColor="#FFFFFF"
          classes="modal-footer-btn"
          bgColor={error ? '#E16486' : '#82B477'}
          onclick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalSucesso.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.bool,
  message: PropTypes.string,
  handleClose: PropTypes.func
};

ModalSucesso.defaultProps = {
  show: false,
  error: false,
  message: '',
  handleClose: () => {}
};

export default ModalSucesso;
