import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import '../assets/scss/components/DuvidaDropdown.scss';

const DuvidaDropdown = ({ children, pergunta }) => {
  const [openedQuestion, setOpenedQuestion] = useState(false);

  return (
    <section id="in-dp">
      <div className="in-dp-grid">
        <div
          className="in-dp-grid-title"
          onClick={() => setOpenedQuestion(!openedQuestion)}
        >
          <span>.</span> <h2>{pergunta}</h2>
        </div>
        <div className="in-dp-grid-icon">
          {openedQuestion ? (
            <SvgIcon
              fontSize="large"
              component={ExpandLess}
              onClick={() => setOpenedQuestion(!openedQuestion)}
            />
          ) : (
            <SvgIcon
              fontSize="large"
              component={ExpandMore}
              onClick={() => setOpenedQuestion(!openedQuestion)}
            />
          )}
        </div>
        <div className={`in-dp-grid-answer ${openedQuestion ? 'opened' : ''}`}>
          {children}
        </div>
      </div>
    </section>
  );
};

DuvidaDropdown.propTypes = {
  children: PropTypes.element,
  pergunta: PropTypes.string
};

DuvidaDropdown.defaultProps = {
  children: React.createElement('div'),
  pergunta: ''
};

export default DuvidaDropdown;
