import React, { useEffect } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import iconYou from '../assets/images/icon_you.svg';
import iconTeam from '../assets/images/icon_team.svg';
import iconTheme from '../assets/images/icon_theme.svg';
import iconCompany from '../assets/images/icon_company.svg';
import iconChallenge from '../assets/images/icon_challenge.svg';

import '../assets/scss/Sobre.scss';

const Sobre = () => {
  const settings = {
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    centerPadding: '275px',
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          centerPadding: '175px'
        }
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '150px'
        }
      },
      {
        breakpoint: 1220,
        settings: {
          centerPadding: '125px'
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '75px'
        }
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '50px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '200px'
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: '35px'
        }
      }
    ]
  };

  useEffect(() => {
    document.title = 'Sobre | Você no Centro de Tudo 3.0';
  }, []);

  return (
    <section id="in-sb">
      <div className="in-sb-ctn">
        <div className="in-sb-ctn-grid">
          <div className="in-sb-ctn-grid-title">
            <h1>Sobre</h1>
          </div>
          <div className="in-sb-ctn-grid-card">
            <Slider {...settings}>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img src={iconYou} alt="Você no centro de tudo 3.0!" />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Você no centro <br /> de tudo 3.0!
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      Esta é uma campanha destinada a engajar os colaboradores e
                      relembrar os benefícios, vantagens e ações que a Chiesi
                      proporciona para valorizar seu trabalho e promover um
                      ambiente colaborativo.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img src={iconCompany} alt="Os pilares da empresa" />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Os pilares <br />
                      da empresa
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      No <strong>Você no Centro de Tudo 3.0</strong>, os
                      colaboradores terão acesso a um conhecimento aprofundado
                      sobre os pilares que sustentam os valores da Chiesi, como
                      o nosso compromisso com a ética e integridade, com a
                      sustentabilidade, colaboradores e pacientes.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img src={iconTheme} alt="Temas Variados" />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Temas <br /> Variados
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      Os colaborados participantes do{' '}
                      <strong>Você no Centro de Tudo 3.0</strong> terão acesso
                      aos desafios com 12 temas variados sobre o dia a dia da
                      Chiesi, onde poderão testar seus conhecimentos e reforçar
                      informações de seu interesse sobre o ambiente de trabalho.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img src={iconTeam} alt="Trabalho de equipe" />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Trabalho <br /> de equipe
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      Uma das partes mais divertidas e importantes do{' '}
                      <strong>Você no Centro de Tudo 3.0</strong> é a interação
                      entre os colaboradores, que podem desafiar uns aos outros
                      e alcançar mais pontos.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img src={iconChallenge} alt="Desafios Chiesi" />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Desafios <br /> Chiesi
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      Após realizar o cadastro e entrar com seus dados, o
                      colaborador participará de desafios especialmente
                      desenvolvidos para mostrar as medidas que a Chiesi toma
                      para valorizar seu ambiente de trabalho e concorrerá a
                      premiações.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="in-sb-ctn-grid-card-item">
                  <div className="in-sb-ctn-grid-card-item-icon">
                    <img
                      src={iconChallenge}
                      alt="Quem participar tem mais chances"
                    />
                  </div>
                  <div className="in-sb-ctn-grid-card-item-title">
                    <h2>
                      Quem participar <br /> tem mais chances!
                    </h2>
                  </div>
                  <div className="in-sb-ctn-grid-card-item-info">
                    <p>
                      As perguntas dos desafios se renovam semanalmente, então
                      quem mais participa, tem mais chance de testar seus
                      conhecimentos sobre a Chiesi e ganhar prêmios.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sobre;
