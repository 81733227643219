import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonDefault from './ButtonDefault';
import InputDefault from './InputDefault';
import { solicitarEmail } from '../services/User';

import '../assets/scss/components/ModalSolicitarEmail.scss';

const ModalSolicitarEmail = ({ show, handleClose, showEmailEnviado }) => {
  const [email, setEmail] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleSolicitar = async (event) => {
    event.preventDefault();
    try {
      await solicitarEmail(email);
      showEmailEnviado();
      handleClose();
    } catch (error) {
      if (error.response.status === 404) {
        setErrorText('Desculpe, mas este e-mail não foi encontrado.');
        setErrorModal(true);
      } else if (error.response.status === 422) {
        setErrorText('E-mail inválido');
        setErrorModal(true);
      }
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-solicitar"
    >
      <Modal.Header>
        <div className="moda-header-close">
          <span onClick={handleClose}>×</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => handleSolicitar(e)}>
          <div className="modal-body-title">
            <h4>Recuperar senha</h4>
          </div>
          <p className="modal-body-text">
            Digite seu e-mail para iniciarmos o processo de recuperação de
            senha.
          </p>
          <div className="modal-body-input">
            <InputDefault
              type="email"
              name="email"
              value={email}
              labelPosition="top"
              label="Digite seu e-mail:"
              onchange={(value) => setEmail(value)}
            />
          </div>
          {errorModal && <p className="modal-body-error">{errorText}</p>}

          <div className="btn-container">
            <ButtonDefault
              bgColor="#b90165"
              textColor="#FFFFFF"
              text="Recuperar Senha"
              classes="modal-footer-btn"
              submit
              disabled={email === ''}
            />
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer /> */}
    </Modal>
  );
};

ModalSolicitarEmail.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  showEmailEnviado: PropTypes.func
};

ModalSolicitarEmail.defaultProps = {
  show: false,
  handleClose: () => {},
  showEmailEnviado: () => {}
};

export default ModalSolicitarEmail;
