import API from '../plugins/axios';

export function signIn(email, senha) {
  return new Promise((resolve, reject) => {
    API.post('login', {
      email,
      password: senha
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function busca(email) {
  return new Promise((resolve, reject) => {
    API.get('usuarios/busca', {
      params: {
        email
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function insere(data) {
  return new Promise((resolve, reject) => {
    API.post('usuarios/insere', {
      ...data
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function solicitarEmail(email) {
  return new Promise((resolve, reject) => {
    API.post('login/esqueci-minha-senha', {
      email
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetSenha(dados) {
  return new Promise((resolve, reject) => {
    API.post('login/resetar-minha-senha', dados)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
