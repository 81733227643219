import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import '../assets/scss/DuvidasLogado.scss';

const DuvidasLogado = () => {
  const [key, setKey] = useState('1');

  useEffect(() => {
    document.title = 'Dúvidas | Você no Centro de Tudo 3.0';
  }, []);

  return (
    <section id="in-dvf">
      <div className="in-dvf-ctn">
        <div className="in-dvf-ctn-grid">
          <div className="in-dvf-ctn-grid-title">
            <h1>Dúvidas Frequentes</h1>
          </div>
          <div className="in-dvf-ctn-grid-ctt">
            <Tabs
              activeKey={key}
              id="controlled-tab-example"
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="1" title="Regras gerais">
                <div className="in-dvf-ctn-grid-ctt-sup">
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quem pode participar do Você no Centro de Tudo 3.0?</h3>
                    <p>
                      Apenas os colaboradores da Chiesi. Para participar dos
                      desafios, é necessário realizar o cadastro e o login com
                      seu e-mail da empresa, de modo que o game é aberto apenas
                      aos funcionários.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quais são os jogos?</h3>
                    <p>
                      São dois jogos: o Desafio Semanal e o Desafio Agora. No
                      Desafio Semanal, o colaborador responde a uma questão que
                      se renova toda semana. No Desafio Agora, a pessoa
                      desafiadora convida alguém para competir diretamente.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Há alguma premiação por participar dos desafios?</h3>
                    <p>
                      Sim. Toda semana haverá um sorteio entre os participantes
                      que acertaram a pergunta do Desafio Semanal. Além disso,
                      ao participar do Você no Centro de Tudo 3.0, o colaborador
                      acumula pontos no decorrer dos jogos e é posicionado em um
                      ranking para concorrer ao prêmio final.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como concorro às premiações no Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O colaborador deve participar dos Desafios Semanais e
                      acertar a pergunta para participar do sorteio das
                      premiações da semana. Além disso, os jogadores com maiores
                      pontuações no Você no Centro de Tudo 3.0 ao final da
                      companha também poderão ganhar prêmios especiais.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Quando o Desafio Agora termina em empate, os dois
                      participantes ganham?
                    </h3>
                    <p>
                      Sim! Se os dois participantes do Você no Centro de Tudo
                      3.0 responderem corretamente ao Desafio Agora, de modo que
                      o resultado seja um empate, ambos ganham 50 pontos e 2
                      diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Quantos pontos eu ganho ao responder corretamente a
                      pergunta do Desafio Agora?
                    </h3>
                    <p>
                      Ao acertar a pergunta do Desafio Agora no Você no Centro
                      de Tudo 3.0, você ganhará 50 pontos e 2 diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Quantos pontos eu ganho ao responder corretamente a
                      pergunta do Desafio Semanal?
                    </h3>
                    <p>
                      Quando responde corretamente à pergunta do Desafio
                      Semanal, você ganha 100 pontos e 5 diamantes. Caso você
                      erre, você ganhará 20 pontos. Você só não pontua se não
                      responder ao Desafio Semanal dentro do prazo previsto no
                      contador de tempo do site
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="2" title="Regras dos games">
                <div className="in-dvf-ctn-grid-ctt-sup">
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como funciona o Desafio Semanal?</h3>
                    <p>
                      É muito simples: uma vez logado no site com os dados de
                      colaborador Chiesi, o participante terá acesso à roleta de
                      perguntas com os temas correspondentes. Ao girar a roleta,
                      uma pergunta será apresentada e o jogador terá o tempo
                      exibido no contador do site, logo acima da questão, para
                      responder antes que o desafio seja renovado.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Posso girar a roleta de perguntas no Desafio Semanal mais
                      de uma vez?
                    </h3>
                    <p>
                      Não. O Desafio Semanal funciona pelo período de 7 dias,
                      sendo resetado após esse período. Uma vez iniciado, não é
                      possível pausar ou girar a roleta novamente.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Errei o Desafio Semanal, e agora?</h3>
                    <p>
                      Não se preocupe! Ao falhar no Desafio Semanal, você recebe
                      20 pontos e ainda tem a oportunidade de participar
                      novamente na próxima semana!
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como funciona o Desafio Agora?</h3>
                    <p>
                      No Desafio Agora, o participante jogará competindo com
                      outro colaborador. Ele terá acesso à pergunta da primeira
                      rodada e, em seguida, o oponente será avisado por e-mail e
                      terá 24 horas para responder.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Qual é a dinâmica do Desafio Agora?</h3>
                    <p>
                      Após clicar em iniciar o desafio, o desafiante deverá
                      girar a roleta e um tema será escolhido. Na sequência, a
                      tela carregará o resumo de informações da pergunta e área,
                      e você terá opções de múltipla escolha para selecionar a
                      resposta.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como saber se fui desafiado?</h3>
                    <p>
                      Assim que for desafiado, você receberá um e-mail com a
                      informação de quem o desafiou e com as regras básicas do
                      game. Após o oponente passar a vez (errando ou acertando a
                      pergunta), chegará um e-mail informando que chegou a sua
                      vez, e que deverá seguir os mesmos processos do game.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quem ganha no Desafio Agora?</h3>
                    <p>
                      Após receber a notificação por e-mail de que é sua vez de
                      jogar, o participante tem 24 horas para responder ao
                      desafio. Ganha quem acertar a pergunta, ou caso a pessoa
                      desafiada não interaja no prazo de 24 horas.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como acumulo diamantes no Desafio Agora?</h3>
                    <p>
                      O vencedor do Desafio Agora ganhará 2 diamantes e 50
                      pontos. Quanto mais você jogar, mais chances de ganhar!
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="3" title="Ranking">
                <div className="in-dvf-ctn-grid-ctt-sup">
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como funciona o ranking do Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O ranking do Você no Centro de Tudo 3.0 mostra a posição
                      geral de todos os participantes do jogo de acordo com suas
                      pontuações e diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como saber em que posição estou no ranking?</h3>
                    <p>
                      Para saber sua posição no Ranking, basta consultar a aba
                      específica. O Ranking mostrará os primeiros colocados e a
                      sua posição em relação aos líderes no placar geral.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O que preciso fazer para subir no ranking?</h3>
                    <p>
                      Para subir em posições no Ranking, basta acumular pontos e
                      diamantes. Por isso, é importante participar de todos os
                      Desafios Semanais e do máximo de Desafios Agora possíveis.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Posso ganhar prêmios por estar nas primeiras posições do
                      ranking?
                    </h3>
                    <p>
                      Sim! O acúmulo de pontos em todos os desafios, coloca o
                      jogador em melhores posições no ranking geral, de modo
                      que, se estiver entre os primeiros colocados ao final do
                      jogo, você também pode ganhar prêmios.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O que é o cronômetro?</h3>
                    <p>
                      O cronômetro mostra quanto tempo resta para responder o
                      Desafio Semanal após ele ser iniciado na Roleta de
                      Perguntas. Responda dentro deste prazo para garantir sua
                      pontuação, pois após o período cronometrado, o Desafio
                      Semanal se renovará automaticamente e você perderá a
                      oportunidade de completar esse Desafio Semanal.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Em qual dia da semana termina o Desafio Semanal?</h3>
                    <p>
                      O Desafio Semanal se inicia toda quarta-feira às 00h00 e
                      termina às 23h59 da terça-feira seguinte. Por isso, fique
                      atento ao cronômetro e responda à pergunta proposta no
                      prazo, para garantir sua pontuação e presença no sorteio
                      semanal.
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="4" title="Pontuações">
                <div className="in-dvf-ctn-grid-ctt-sup">
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quantos pontos ganho no Desafio Agora?</h3>
                    <p>
                      O colaborador que participa da modalidade Desafio Agora,
                      onde joga competindo com outro participante, se vencer,
                      ganha 50 pontos e 2 diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Ganho pontos mesmo se perder no Desafio Agora?</h3>
                    <p>
                      Não. A modalidade Desafio Agora só pontua a pessoa que
                      acertar a resposta entre os competidores que estão
                      jogando., mas a pessoa que perder o desafio ganhará os
                      diamantes já conquistados.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      No Desafio Semanal é possível ganhar pontos mesmo se errar
                      a pergunta?
                    </h3>
                    <p>
                      Sim! O Desafio Semanal premia a sua participação e, se
                      errar, você ainda ganha 20 pontos e tem a oportunidade de
                      tentar novamente na próxima semana.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como funciona o Ranking do Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O ranking mostra a posição dos participantes no placar
                      geral do jogo. Para subir no ranking, basta acumular
                      pontos e diamantes participando dos desafios.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como subir no rankeamento?</h3>
                    <p>
                      Basta participar do maior número de desafios possível,
                      desafiar outros participantes e sempre responder ao
                      Desafio Semanal.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O ranking acumula meus pontos e diamantes?</h3>
                    <p>
                      Sim! O ranking funciona com a somatória das pontuações
                      obtidas em todos os jogos do Desafio.
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="5" title="Todas as opções">
                <div className="in-dvf-ctn-grid-ctt-sup">
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quem pode participar do Você no Centro de Tudo 3.0?</h3>
                    <p>
                      Apenas os colaboradores da Chiesi. Para participar dos
                      desafios, é necessário realizar o cadastro e o login com
                      seu e-mail da empresa, de modo que o game é aberto apenas
                      aos funcionários.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quais são os games?</h3>
                    <p>
                      São dois games: o Desafio Semanal e o Desafio Agora. No
                      Desafio Semanal, o colaborador responde a uma questão que
                      se renova toda semana. No Desafio Agora, o jogador convida
                      outro participante para competir como oponente direto.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Há alguma premiação por participar dos desafios?</h3>
                    <p>
                      Sim. Toda semana haverá um sorteio entre os participantes
                      que acertaram a pergunta do Desafio Semanal. Além disso,
                      ao participar do Você no Centro de Tudo 3.0, o colaborador
                      acumula pontos no decorrer dos jogos e é posicionado em um
                      ranking para concorrer ao prêmio final.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como concorro às premiações no Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O colaborador deve participar dos Desafios Semanais e
                      acertar a pergunta para participar do sorteio das
                      premiações da semana. Além disso, os jogadores com maiores
                      pontuações no Você no Centro de Tudo 3.0, ao final da
                      companha, também poderão ganhar prêmios especiais.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como funciona o Desafio Semanal?</h3>
                    <p>
                      É muito simples: uma vez logado no site com seus dados de
                      colaborador Chiesi, o participante terá acesso à roleta de
                      perguntas com os temas correspondentes. Ao girar a roleta,
                      uma pergunta será apresentada e o jogador terá o tempo
                      exibido no contador do site, logo acima da questão, para
                      responder antes que o desafio seja renovado.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Posso girar a roleta de perguntas no Desafio Semanal mais
                      de uma vez?
                    </h3>
                    <p>
                      Não. O Desafio Semanal funciona pelo período de 7 dias,
                      sendo resetado após esse período. Uma vez iniciado, não é
                      possível pausar ou girar a roleta novamente.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Errei o Desafio Semanal, e agora?</h3>
                    <p>
                      Não se preocupe! Ao falhar no Desafio Semanal, você recebe
                      20 pontos e ainda tem a oportunidade de participar
                      novamente na próxima semana!
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como funciona o Desafio Agora?</h3>
                    <p>
                      No Desafio Agora, o participante jogará competindo com
                      outro colaborador. Ele terá acesso à pergunta da primeira
                      rodada e, em seguida, o oponente será avisado por e-mail e
                      terá 24 horas para responder.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Qual é a dinâmica do Desafio Agora?</h3>
                    <p>
                      Após clicar em iniciar o desafio, o desafiante deverá
                      girar a roleta e um tema será escolhido. Na sequência, a
                      tela carregará o resumo de informações da pergunta e área,
                      e você terá opções de múltipla escolha para selecionar a
                      resposta.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como saber se fui desafiado?</h3>
                    <p>
                      Assim que for desafiado, você receberá um e-mail com a
                      informação de quem o desafiou e com as regras básicas do
                      game. Após o oponente passar a vez (errando ou acertando a
                      pergunta), chegará um e-mail informando que chegou a sua
                      vez, e que deverá seguir os mesmos processos do game.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quem ganha no Desafio Agora?</h3>
                    <p>
                      Após receber a notificação por e-mail de que é sua vez de
                      jogar, o participante tem 24 horas para responder ao
                      desafio. Ganha o participante que acertar a pergunta, ou
                      caso o desafiado não interaja dentro do prazo de 24 horas.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como acumulo diamantes no Desafio Agora?</h3>
                    <p>
                      O vencedor do Desafio Agora ganhará 2 diamantes e 50
                      pontos. Quanto mais você jogar, mais chances de ganhar!
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como funciona o ranking do Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O ranking do Você no Centro de Tudo 3.0 mostra a posição
                      geral de todos os participantes do jogo de acordo com suas
                      pontuações e diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como saber em que posição estou no ranking?</h3>
                    <p>
                      Para saber sua posição no Ranking, basta consultar a aba
                      específica. O Ranking mostrará os primeiros colocados e a
                      sua posição em relação aos líderes no placar geral.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O que preciso fazer para subir no ranking?</h3>
                    <p>
                      Para subir em posições no Ranking, basta acumular pontos e
                      diamantes. Por isso, é importante participar de todos os
                      Desafios Semanais e do máximo de Desafios Agora possíveis.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Posso ganhar prêmios por estar nas primeiras posições do
                      ranking?
                    </h3>
                    <p>
                      Sim! O acúmulo de pontos em todos os desafios, coloca o
                      jogador em melhores posições no ranking geral, de modo
                      que, se estiver entre os primeiros colocados ao final do
                      jogo, você também pode ganhar prêmios.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O que é o cronômetro?</h3>
                    <p>
                      O cronômetro mostra quanto tempo resta para responder o
                      Desafio Semanal após ele ser iniciado na Roleta de
                      Perguntas. Responda dentro deste prazo para garantir sua
                      pontuação, pois após o período cronometrado, o Desafio
                      Semanal se renovará automaticamente e você perderá a
                      oportunidade de completar esse Desafio Semanal.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Em qual dia da semana termina o Desafio Semanal?</h3>
                    <p>
                      O Desafio Semanal se inicia toda quarta-feira às 00h00 e
                      termina às 23h59 da terça-feira seguinte. Por isso, fique
                      atento ao cronômetro e responda à pergunta proposta no
                      prazo, para garantir sua pontuação e presença no sorteio
                      semanal.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Quantos pontos ganho no Desafio Agora?</h3>
                    <p>
                      O colaborador que participa da modalidade Desafio Agora,
                      onde joga competindo com outro participante, se vencer,
                      ganha 50 pontos e 2 diamantes.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Ganho pontos mesmo se perder no Desafio Agora?</h3>
                    <p>
                      Não. A modalidade Desafio Agora só pontua o vencedor entre
                      os competidores que estão jogando.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      No Desafio Semanal é possível ganhar pontos mesmo se errar
                      a pergunta?
                    </h3>
                    <p>
                      Sim! O Desafio Semanal premia a sua participação e, se
                      errar, você ainda ganha 20 pontos e tem a oportunidade de
                      tentar novamente na próxima semana.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>
                      Como funciona o Ranking do Você no Centro de Tudo 3.0?
                    </h3>
                    <p>
                      O ranking mostra a posição dos participantes no placar
                      geral do jogo. Para subir no ranking, basta acumular
                      pontos e diamantes participando dos desafios.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>Como subir no rankeamento?</h3>
                    <p>
                      Basta participar do maior número de desafios possível,
                      desafiar outros participantes e sempre responder ao
                      Desafio Semanal.
                    </p>
                  </div>
                  <div className="in-dvf-ctn-grid-ctt-sup-item">
                    <h3>O ranking acumula meus pontos e diamantes?</h3>
                    <p>
                      Sim! O ranking funciona com a somatória das pontuações
                      obtidas em todos os jogos dos desafios.
                    </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DuvidasLogado;
