import React, { useEffect } from 'react';

import DuvidaDropdown from '../components/DuvidaDropdown';
import avatarDoubt from '../assets/images/avatar_doubt.svg';

import '../assets/scss/DuvidasDeslogado.scss';

const DuvidasDeslogado = () => {
  useEffect(() => {
    document.title = 'Dúvidas | Você no Centro de Tudo 3.0';
  }, []);

  return (
    <section id="in-dv">
      <div className="in-dv-ctn">
        <div className="in-dv-ctn-grid">
          <div className="in-dv-ctn-grid-title">
            <h1>Dúvidas</h1>
          </div>
          <div className="in-dv-ctn-grid-ctt">
            <div className="in-dv-ctn-grid-ctt-lf">
              <img src={avatarDoubt} alt="Ilustração da Equipe da Chiesi" />
            </div>
            <div className="in-dv-ctn-grid-ctt-rt">
              <DuvidaDropdown pergunta="O que é o programa Você no Centro de Tudo 3.0?">
                <p>
                  Esta é uma campanha destinada a engajar os colaboradores e
                  relembrar os benefícios, vantagens e ações que a Chiesi
                  proporciona para valorizar seu trabalho e promover um ambiente
                  colaborativo.
                </p>
              </DuvidaDropdown>
              <DuvidaDropdown pergunta="Como participar do programa?">
                <p>
                  Para participar, basta realizar seu cadastro de colaborador e
                  responder às perguntas dos desafios que o Você no Centro de
                  Tudo 3.0 apresentará em nossa área especial do game.
                </p>
              </DuvidaDropdown>
              <DuvidaDropdown pergunta="Como realizar o cadastro?">
                <p>
                  Para fazer seu cadastro, clique em &quot;cadastrar&quot;,
                  preencha oscampos com os dados solicitados, insira seu e-mail,
                  crie uma senha e um apelido, depois clique em
                  &quot;concordo&quot; no campo correspondente.
                </p>
              </DuvidaDropdown>
              <DuvidaDropdown pergunta="Quais informações são necessárias para realizar o cadastro?">
                <p>
                  Para realizar o cadastro, será necessário fornecer as
                  informações solicitadas no formulário, como e-mail, apelido e
                  senha. Os demais dados serão carregados automaticamente.
                </p>
              </DuvidaDropdown>
              <DuvidaDropdown pergunta="Como os dados pessoais dos colaboradores são utilizados no programa?">
                <p>
                  Os dados são protegidos e utilizados apenas de maneira
                  interna, para o controle da participação dos colaboradores no
                  programa Você no Centro de Tudo 3.0. Não compartilhamos dados
                  pessoais ou profissionais com nenhum agente externo.
                </p>
              </DuvidaDropdown>
              <DuvidaDropdown pergunta="Só colaboradores Chiesi podem participar do programa?">
                <p>
                  Sim, apenas os colaboradores da Chiesi poderão participar do
                  programa e terão acesso à área interna dos desafios. Para
                  isso, é necessário realizar o cadastro com o e-mail
                  profissional da empresa.
                </p>
              </DuvidaDropdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DuvidasDeslogado;
