import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonDefault from './ButtonDefault';

import '../assets/scss/components/ModalEmailEnviado.scss';

const ModalEmailEnviado = ({ show, handleClose }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-email-enviado"
    >
      <Modal.Header>
        <div className="moda-header-close">
          <span onClick={handleClose}>×</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-title">
          <h4>Pronto!</h4>
        </div>
        <p className="modal-body-text">
          Enviamos um e-mail com orientações para alteração de senha.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefault
          bgColor="#141529"
          textColor="#FFFFFF"
          text="Fechar"
          classes="modal-footer-btn"
          onclick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalEmailEnviado.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

ModalEmailEnviado.defaultProps = {
  show: false,
  handleClose: () => {}
};

export default ModalEmailEnviado;
