/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// import { SvgIcon } from '@material-ui/core';
// import { InfoOutlined } from '@material-ui/icons';

import getDesafios from '../services/Desafios';

import iconUser from '../assets/images/icon_user.svg';
import ButtonDefault from '../components/ButtonDefault';

import thumb1 from '../assets/images/thumb-01.png';
import thumb2 from '../assets/images/thumb-02.png';
import thumb3 from '../assets/images/thumb-03.jpg';
import thumb4 from '../assets/images/thumb-04.png';
// import thumb5 from '../assets/images/thumb-05.png';

import '../assets/scss/Desafios.scss';

const Desafios = () => {
  const [data, setData] = useState({});
  const [soma, setSoma] = useState(0);
  const history = useHistory();

  useEffect(() => {
    document.title = 'Desafios | Você no Centro de Tudo 3.0';
  }, []);

  const ancora = (table) => {
    document.querySelector(`.in-df-ctn-grid-${table}`).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
  };

  useEffect(() => {
    let unmounted = false;
    const loadData = async () => {
      try {
        const resp = await getDesafios();
        if (!unmounted) {
          setData(resp);
          setSoma(resp.desafios.semanais.total + resp.desafios.agora.total);
        }
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          history.push('/');
        }
      }
    };
    loadData();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <section id="in-df">
      <div className="in-df-ctn">
        <div className="in-df-ctn-grid">
          <div className="in-df-ctn-grid-title">
            <h1>Seu perfil - Desafios</h1>
          </div>
          <div className="in-df-ctn-grid-webstories">
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/geral/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb4} alt="Ícone foguete" />
                Geral
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/topzeiros/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="poster"
                  src={thumb3}
                  alt="Ícone bandeira de largada"
                />
                Topzeiros
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/sortudos/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb2} alt="Ícone de balão" />
                Sortudos
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/centraliza/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb1} alt="Ícone de roleta" />
                Centraliza
              </a>
            </div>
            {/* <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/voce-no-centro-de-tudo-os-sortudos/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb4} alt="" />
              </a>
            </div> */}
            {/* <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/voce-no-centro-de-tudo-por-dentro-de-tudo/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb5} alt="" />
              </a>
            </div> */}
          </div>
          <div className="in-df-ctn-grid-ctt">
            <div className="in-df-ctn-grid-ctt-item">
              {/* {data.desafios?.semanais?.aguardando.length > 0 && (
                <div className="in-df-ctn-grid-ctt-item-msg">
                  <SvgIcon component={InfoOutlined} fontSize="large" />
                  <p>
                    Você tem {data.desafios?.semanais?.aguardando.length}{' '}
                    desafio
                    {data.desafios?.semanais?.aguardando.length > 1
                      ? 's'
                      : ''}{' '}
                    em aberto.
                  </p>
                </div>
              )} */}
              <div className="in-df-ctn-grid-ctt-item-info">
                <div className="in-df-ctn-grid-ctt-item-info-title">
                  <h2>Desafios Semanais</h2>
                </div>
                <div className="in-df-ctn-grid-ctt-item-info-data">
                  <p>Você já participou de</p>
                  <p>{data.desafios?.semanais?.total}</p>
                  <p>desafios.</p>
                </div>
                <div className="in-df-ctn-grid-ctt-item-info-action">
                  <ButtonDefault
                    text="Ver Histórico"
                    bgColor="#b5dc33"
                    textColor="#000"
                    classes="in-df-ctn-grid-ctt-item-info-action-btn"
                    onclick={() => ancora('ds')}
                  />
                </div>
              </div>
            </div>
            <div className="in-df-ctn-grid-ctt-item">
              {/* {data.desafios?.agora?.aguardando.length > 0 && (
                <div className="in-df-ctn-grid-ctt-item-msg">
                  <SvgIcon component={InfoOutlined} fontSize="large" />
                  <p>
                    Você tem {data.desafios?.agora?.aguardando.length} desafio
                    {data.desafios?.agora?.aguardando.length > 1 ? 's' : ''} em
                    aberto.
                  </p>
                </div>
              )} */}
              <div className="in-df-ctn-grid-ctt-item-info">
                <div className="in-df-ctn-grid-ctt-item-info-title">
                  <h2>Desafios Agora</h2>
                </div>
                <div className="in-df-ctn-grid-ctt-item-info-data">
                  <p>Você já participou de</p>
                  <p>{data.desafios?.agora?.total}</p>
                  <p>desafios.</p>
                </div>
                <div className="in-df-ctn-grid-ctt-item-info-action">
                  <ButtonDefault
                    text="Ver Histórico"
                    bgColor="#5a3296"
                    textColor="#fff"
                    onclick={() => ancora('da')}
                    classes="in-df-ctn-grid-ctt-item-info-action-btn"
                  />
                </div>
              </div>
            </div>
            <div className="in-df-ctn-grid-ctt-item">
              <div className="in-df-ctn-grid-ctt-item-info">
                <div className="in-df-ctn-grid-ctt-item-info-title">
                  <h2>Desafios Totais</h2>
                </div>
                <div className="in-df-ctn-grid-ctt-item-info-data">
                  <p>Você já participou de</p>
                  <p>{soma}</p>
                  <p>desafios no total.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="in-df-ctn-grid-ds">
            <h2>Histórico de desafios semanais</h2>
            <div className="in-df-ctn-grid-ds-table">
              <table>
                <thead>
                  <tr>
                    <th>Desafio Semanal</th>
                    <th>Resultado</th>
                    <th>Pontos</th>
                    <th>Diamantes</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.desafios?.semanais?.desafios.map((item) => {
                    // const statusClass = item.status
                    //   .replaceAll(' ', '_')
                    //   .toLowerCase();
                    return (
                      <tr key={item.cod_desafio}>
                        <td>{item.semana}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.resultado
                            }}
                          />
                        </td>
                        <td>{item.pontos}</td>
                        <td>{item.diamantes}</td>
                        <td>
                          -
                          {/* {statusClass === 'jogar_agora' ? (
                            <Link to="/desafio-semanal">
                              <span className={statusClass}>{item.status}</span>
                            </Link>
                          ) : (
                            <span className={statusClass}>{item.status}</span>
                          )} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="in-df-ctn-grid-da">
            <h2>Histórico de desafios agora</h2>
            <div className="in-df-ctn-grid-da-table">
              <table>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Oponente</th>
                    <th>Resultado</th>
                    <th>Pontos</th>
                    <th>Diamantes</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.desafios?.agora?.desafios.map((item) => {
                    // const statusClass = item.status
                    //   .replaceAll(' ', '_')
                    //   .toLowerCase();
                    return (
                      <tr key={item.cod_desafio}>
                        <td>{moment(item.created_at).format('D/M/YYYY')}</td>
                        <td>
                          <div>
                            <div>
                              {item.oponente?.foto !== null ? (
                                <img
                                  src={item.oponente?.foto}
                                  alt={item.oponente?.foto}
                                />
                              ) : (
                                <img src={iconUser} alt={iconUser} />
                              )}
                            </div>
                            <span>{item.oponente?.nome}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.resultado
                            }}
                          />
                        </td>
                        <td>{item.pontos}</td>
                        <td>{item.diamantes}</td>
                        <td>
                          -
                          {/* {statusClass === 'jogar_agora' ? (
                            <Link to={`/desafio-agora/${item.cod_desafio}`}>
                              <div className={statusClass}>{item.status}</div>
                            </Link>
                          ) : (
                            <div className={statusClass}>{item.status}</div>
                          )} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Desafios;
