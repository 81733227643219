import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuOpened: false,
    showFooter: true
  },
  reducers: {
    setOpened: (state, action) => {
      state.menuOpened = action.payload;
    },
    setFooter: (state, action) => {
      state.showFooter = action.payload;
    }
  }
});

export const { setOpened, setFooter } = menuSlice.actions;

export default menuSlice.reducer;
