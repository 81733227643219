import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ButtonDefault from '../components/ButtonDefault';
import InputDefault from '../components/InputDefault';
import ModalCadastro from '../components/ModalCadastro';
import ModalSucessoCadastro from '../components/ModalSucessoCadastro';
import ModalEmailEnviado from '../components/ModalEmailEnviado';

import '../assets/scss/Home.scss';

import logoVoceNoCentroG from '../assets/images/logo-branca-GRANDE.svg';
import logoChiesi from '../assets/images/logo_chiesi.svg';
import {
  changeSigned,
  setUserData,
  setPhoto,
  showModalCadastro
} from '../features/userSlice';
import { signIn } from '../services/User';
import ModalSolicitarEmail from '../components/ModalSolicitarEmail';

const Home = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erroLogin, setErroLogin] = useState(false);
  const [showModalSucesso, setShowModalSucesso] = useState(false);
  const [showModalSolicitar, setShowModalSolicitar] = useState(false);
  const [showModalEmailEnviado, setShowModalEmailEnviado] = useState(false);
  const showModal = useSelector((state) => state.user.modalCadastro);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Home | Você no Centro de Tudo 3.0';
  }, []);

  const handleClose = () => {
    dispatch(showModalCadastro(!showModal));
  };
  const handleCloseSucesso = () => {
    setShowModalSucesso(!showModalSucesso);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const resp = await signIn(email, senha);
      localStorage.setItem('@user', JSON.stringify(resp));
      localStorage.setItem('@token', resp.token);
      localStorage.setItem('@ranking', resp.ranking);
      localStorage.setItem('@pontos', resp.pontos);
      localStorage.setItem('@diamantes', resp.diamantes);
      localStorage.setItem('@desafio_semanal', resp.desafio_semanal);
      localStorage.setItem('@primeiro_acesso', resp.primeiro_acesso);
      localStorage.setItem('@imagem_perfil', resp.imagem_perfil);
      dispatch(setUserData(resp));
      dispatch(changeSigned(!!localStorage.getItem('@user')));
      dispatch(setPhoto(resp.imagem_perfil));
    } catch (error) {
      if (error.response.status === 401) {
        setErroLogin(true);
      }
    }
  };

  return (
    <section id="in-hm">
      <ModalEmailEnviado
        show={showModalEmailEnviado}
        handleClose={() => setShowModalEmailEnviado(!showModalEmailEnviado)}
      />
      <ModalSucessoCadastro
        handleClose={handleCloseSucesso}
        show={showModalSucesso}
      />
      <ModalCadastro
        handleClose={handleClose}
        show={showModal}
        handleModalSuccess={handleCloseSucesso}
        showRecuperar={() => setShowModalSolicitar(true)}
      />
      <ModalSolicitarEmail
        show={showModalSolicitar}
        handleClose={() => setShowModalSolicitar(!showModalSolicitar)}
        showEmailEnviado={() =>
          setShowModalEmailEnviado(!showModalEmailEnviado)
        }
      />
      <div className="in-hm-ctn">
        <div className="in-hm-ctn-grid">
          <div className="in-hm-ctn-grid-lf">
            <img src={logoVoceNoCentroG} alt="Você no Centro de Tudo 3.0" />
            <p>
              Aqui, você descobre porque o colaborador está no centro de tudo na
              Chiesi, de maneira divertida! Conheça tudo o que fazemos para
              valorizar o seu trabalho e proporcionar melhorias para a sua
              vivência profissional. Ganhe prêmios participando de nossos
              desafios!
            </p>
            <Link to="/sobre">
              <ButtonDefault
                type="button"
                text="Saiba +"
                bgColor="#b5db42"
                textColor="#000"
                classes="in-hm-ctn-grid-lf-btn"
              />
            </Link>
          </div>
          <div className="in-hm-ctn-grid-rt--bg-white">
            <div className="in-hm-ctn-grid-rt">
              <div className="in-hm-ctn-grid-rt-ctt">
                <form
                  className="in-hm-ctn-grid-rt-ctt-frm-login"
                  onSubmit={(e) => handleLogin(e)}
                >
                  <div className="in-hm-ctn-grid-rt-ctt-frm-login--bg">
                    <div className="in-hm-ctn-grid-rt-image">
                      <img src={logoChiesi} alt="Chiesi" />
                    </div>

                    <div className="in-hm-ctn-grid-rt-ctt-input">
                      <InputDefault
                        type="email"
                        label="E-mail"
                        name="email"
                        value={email}
                        onchange={(value) => setEmail(value)}
                      />
                      <InputDefault
                        type="password"
                        label="Senha"
                        name="senha"
                        value={senha}
                        onchange={(value) => setSenha(value)}
                      />
                    </div>
                    {erroLogin && (
                      <p className="erroLogin">Usuário ou senha incorretos</p>
                    )}
                    <div className="in-hm-ctn-grid-rt-ctt-lost">
                      <ButtonDefault
                        bgColor=""
                        textColor="#545454"
                        text="Recuperar senha"
                        classes="in-hm-ctn-grid-rt-ctt-lost-btn"
                        onclick={() => setShowModalSolicitar(true)}
                      />
                    </div>
                  </div>

                  <div className="in-hm-ctn-grid-rt-ctt-btn">
                    <ButtonDefault
                      text="Entrar"
                      textColor="#fff"
                      classes="in-hm-ctn-grid-rt-ctt-btn-login"
                      submit
                      disabled={!(email !== '' && senha !== '')}
                    />
                    <ButtonDefault
                      bgColor="#b90165"
                      text="Cadastre-se"
                      textColor="#FFFFFF"
                      classes="in-hm-ctn-grid-rt-ctt-btn-register"
                      onclick={() => dispatch(showModalCadastro(true))}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
