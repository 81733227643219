import API from '../plugins/axios';

export default function getDesafios() {
  return new Promise((resolve, reject) => {
    API.get('desafios', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@token')}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
