import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonDefault from './ButtonDefault';

import '../assets/scss/components/ModalSucessoCadastro.scss';

const ModalSucessoCadastro = ({ show, handleClose }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-sucesso-cadastro"
      backdropClassName="backdrop-sucesso-cadastro"
    >
      <Modal.Header>
        <div className="moda-header-close">
          <span onClick={handleClose}>×</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-title">
          <h4>Seu cadastro foi realizado com sucesso</h4>
        </div>
        <p className="modal-body-text">
          Agora você pode participar dos desafios do
          <strong> Você no Centro de Tudo 3.0!</strong>
          <br />
          Enviamos um e-mail com mais detalhes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefault
          bgColor="#b90165"
          textColor="#FFFFFF"
          text="Entrar"
          classes="modal-footer-btn"
          onclick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalSucessoCadastro.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

ModalSucessoCadastro.defaultProps = {
  show: false,
  handleClose: () => {}
};

export default ModalSucessoCadastro;
