import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    signed: !!localStorage.getItem('@user'),
    userData: {},
    photo:
      localStorage.getItem('@imagem_perfil') === 'null'
        ? null
        : localStorage.getItem('@imagem_perfil'),
    modalCadastro: false
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    changeSigned: (state, action) => {
      state.signed = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
    showModalCadastro: (state, action) => {
      state.modalCadastro = action.payload;
    }
  }
});

export const { changeSigned, setUserData, setPhoto, showModalCadastro } =
  userSlice.actions;

export default userSlice.reducer;
