import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import iconUser from '../assets/images/icon_user.svg';
// import iconTrophy from '../assets/images/icon_trophy.svg';
import iconDiamond from '../assets/images/icon_diamond.svg';

import '../assets/scss/Ranking.scss';
import getRanking from '../services/Ranking';
import ButtonDefault from '../components/ButtonDefault';

import thumb1 from '../assets/images/thumb-01.png';
import thumb2 from '../assets/images/thumb-02.png';
import thumb3 from '../assets/images/thumb-03.jpg';
import thumb4 from '../assets/images/thumb-04.png';

const Ranking = () => {
  const [ranking, setRanking] = useState({});
  const userData = JSON.parse(localStorage.getItem('@user'));
  const [rankingAberto, setRankingAberto] = useState(true);

  const history = useHistory();

  useEffect(() => {
    document.title = 'Ranking | Você no Centro de Tudo 3.0';
  }, []);

  useEffect(() => {
    let unmounted = false;
    const loadRanking = async () => {
      try {
        const resp = await getRanking();
        if (!unmounted) {
          setRanking(resp);
        }
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          history.push('/');
        }
      }
    };
    loadRanking();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <section id="in-rk">
      <div className="in-rk-ctn">
        <div className="in-rk-ctn-grid">
          <div className="in-df-ctn-grid-webstories">
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/geral/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb4} alt="Ícone foguete" />
                Geral
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/topzeiros/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="poster"
                  src={thumb3}
                  alt="Ícone bandeira de largada"
                />
                Topzeiros
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/sortudos/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb2} alt="Ícone de balão" />
                Sortudos
              </a>
            </div>
            <div>
              <a
                href="https://webstories.vocenocentrodetudo.com.br/centraliza/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="poster" src={thumb1} alt="Ícone de roleta" />
                Centraliza
              </a>
            </div>
          </div>
          <div className="in-rk-ctn-grid-title">
            <span>Obrigada pela participação!</span>
            <h1>{rankingAberto ? 'Ranking Completo' : 'Ranking'}</h1>
          </div>
          <div
            className={`in-rk-ctn-grid-ctt ${!rankingAberto ? 'closed' : ''}`}
          >
            {!rankingAberto && (
              <div className="in-rk-ctn-grid-ctt-card">
                <div className="in-rk-ctn-grid-ctt-card-info">
                  <div className="in-rk-ctn-grid-ctt-card-info-image">
                    <div className="img-effect">
                      <div className="img-container">
                        {localStorage.getItem('@imagem_perfil') !== 'null' ? (
                          <img
                            src={localStorage.getItem('@imagem_perfil')}
                            alt={localStorage.getItem('@imagem_perfil')}
                          />
                        ) : (
                          <img src={iconUser} alt={iconUser} />
                        )}
                      </div>
                    </div>
                    <div>
                      <h2>
                        {userData.nome?.split(' ')[0]}{' '}
                        {userData.nome?.split(' ')[1]
                          ? `${
                              userData.nome?.split(' ').slice(-1).join(' ')[0]
                            }.`
                          : ''}
                      </h2>
                      <p>{userData.area}</p>
                    </div>
                  </div>
                  <div className="in-rk-ctn-grid-ctt-card-info-values">
                    <div className="in-rk-ctn-grid-ctt-card-info-values-item">
                      <p>Ranking</p>
                      <p>{ranking.meu_ranking?.ranking}º</p>
                    </div>
                    <div className="in-rk-ctn-grid-ctt-card-info-values-item">
                      <p>Pontos</p>
                      <p>{ranking.meu_ranking?.pontos}</p>
                    </div>
                    <div className="in-rk-ctn-grid-ctt-card-info-values-item">
                      <p>Diamantes</p>
                      <div>
                        <img src={iconDiamond} alt={iconDiamond} />
                        <span>{ranking.meu_ranking?.diamantes}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="in-rk-ctn-grid-ctt-card-btn">
                  <ButtonDefault
                    bgColor="#b4dd33"
                    textColor="black"
                    text="Ver Ranking Completo"
                    classes="in-rk-ctn-grid-ctt-card-btn-dft"
                    onclick={() => setRankingAberto(true)}
                  />
                </div>
              </div>
            )}
            <table className="in-rk-ctn-grid-ctt-table">
              <thead className="in-rk-ctn-grid-ctt-table-thead">
                <tr>
                  <th>
                    <div>
                      <span>Ranking</span> <span>Colaborador</span>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Pontos</span>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Diamantes</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="in-rk-ctn-grid-ctt-table-tbody">
                {!rankingAberto && (
                  <>
                    {ranking.ranking?.slice(0, 5).map((item) => (
                      <tr key={item.cod_usuario}>
                        <td>
                          <div>
                            <span>{item.posicao}</span>
                            <div>
                              <div>
                                {item.foto !== null ? (
                                  <img src={item.foto} alt={item.foto} />
                                ) : (
                                  <img src={iconUser} alt={iconUser} />
                                )}
                              </div>
                              <span>{item.nome}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{item.pontos}</span>
                        </td>
                        <td>
                          <div>
                            <img src={iconDiamond} alt={iconDiamond} />
                            <span>{item.diamantes}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                {rankingAberto && (
                  <>
                    {ranking.ranking?.map((item) => (
                      <tr key={item.cod_usuario}>
                        <td>
                          <div>
                            <span>{item.posicao}</span>
                            <div>
                              <div>
                                {item.foto !== null ? (
                                  <img src={item.foto} alt={item.foto} />
                                ) : (
                                  <img src={iconUser} alt={iconUser} />
                                )}
                              </div>
                              <span>{item.nome}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{item.pontos}</span>
                        </td>
                        <td>
                          <div>
                            <img src={iconDiamond} alt={iconDiamond} />
                            <span>{item.diamantes}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {rankingAberto && (
              <ButtonDefault
                text="Voltar"
                bgColor="#E16486"
                textColor="#FFFFFF"
                classes="in-rk-ctn-grid-ctt-table-tbody-btn"
                onclick={() => setRankingAberto(false)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ranking;
