import React, { useEffect } from 'react';

import '../assets/scss/NotFound.scss';

import bgRoulette01 from '../assets/images/bg_roulette_01.png';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 | Você no Centro de Tudo 3.0';
  }, []);

  return (
    <section id="in-nf">
      <div className="in-nf-bg">
        <img src={bgRoulette01} alt={bgRoulette01} />
      </div>
      <div className="in-nf-ctn">
        <div className="in-nf-ctn-grid">
          <div className="in-nf-ctn-grid-title">
            <h1>404 Not Found!</h1>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NotFound;
