import axios from 'axios';

export default axios.create({
  baseURL: process.env?.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'https://api.vocenocentrodetudo.com.br/v1',
  headers: {
    Accept: 'application/json'
  }
});
