import React, { useEffect } from 'react';

import '../assets/scss/Premiacoes.scss';

const Premiacoes = () => {
  useEffect(() => {
    document.title = 'Premiações | Você no Centro de Tudo 3.0';
  }, []);

  return (
    <section id="in-pm">
      <div className="in-pm-ctn">
        <div className="in-pm-ctn-grid">
          <div className="in-pm-ctn-grid-title">
            <h1>Premiações</h1>
          </div>
          <div className="in-pm-ctn-grid-ctt">
            <div className="in-pm-ctn-grid-ctt-sup">
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>Como ganho premiações no Você no Centro de Tudo 3.0?</h3>
                <p>
                  Para concorrer às premiações, os colaboradores devem
                  participar dos desafios semanais, além de acumular mais pontos
                  no Desafio Agora. <br /> Os jogadores com maior pontuação ao
                  fim da experiência Você no Centro de Tudo 3.0, também podem
                  ganhar prêmios da Chiesi com base no ranking.
                </p>
              </div>
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>
                  Para concorrer aos prêmios, basta participar do desafio
                  semanal?
                </h3>
                <p>
                  Esta é uma das possibilidades. <br /> Ao participar do Desafio
                  Semanal e responder corretamente à pergunta, o colaborador
                  ganha a oportunidade de participar do sorteio deste desafio,
                  também realizado semanalmente.
                </p>
              </div>
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>O desafio agora também pode me render prêmios?</h3>
                <p>
                  Sim, uma vez que, ao vencer o Desafio Agora na competição com
                  outro colaborador, você acumula pontos e diamantes e melhora
                  sua posição para concorrer ao prêmio final.
                </p>
              </div>
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>Também posso ganhar prêmios por pontuação?</h3>
                <p>
                  Sim! O acúmulo de pontos em todos os desafios coloca o jogador
                  em melhores posições no ranking geral, de modo que, se estiver
                  entre os primeiros colocados ao final do jogo, podem ganhar
                  prêmios.
                </p>
              </div>
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>
                  Posso participar do sorteio da semana e ser premiado mesmo
                  tendo errado no Desafio Semanal?
                </h3>
                <p>
                  Não. O sorteio semanal pode premiar apenas aos colaboradores
                  que acertaram a pergunta do desafio. Mas não se preocupe, você
                  pode tentar novamente na próxima semana.
                </p>
              </div>
              <div className="in-pm-ctn-grid-ctt-sup-item">
                <h3>
                  Mas e se houver empate entre os melhores colocados do ranking?
                </h3>
                <p>
                  A quantidade de diamantes acumulados durante a campanha será
                  utilizada como critério de desempate. Ainda assim, se não for
                  suficiente, será adicionado o critério de quantidade de
                  acertos de todos os Desafios Semanais e Desafios Agora
                  jogados.
                </p>
              </div>
            </div>
            <div className="in-pm-ctn-grid-ctt-sub">
              <div className="in-pm-ctn-grid-ctt-sub-title">
                <h2>Quais serão os prêmios?</h2>
              </div>
              <div className="in-pm-ctn-grid-ctt-sub-info">
                <div className="in-pm-ctn-grid-ctt-sub-info-item">
                  <h4>Prêmio Desafio Semanal</h4>
                  <p>
                    O <span>Você no Centro de Tudo 3.0</span> premiará 3
                    colaboradores por semana, sendo um da fábrica, um do
                    escritório e um da força de vendas, a partir de um sorteio
                    com os jogadores que acertarem a pergunta do desafio da
                    semana. O sorteio será realizado de forma automática aqui no
                    site e os vencedores serão informados por e-mail da campanha
                    e compartilhados com todos os colaboradores nos canais de
                    comunicação internos.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-info-card">
                  <p>
                    Premiação Semanal: voucher de <span>R$250,00</span>.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-info-item">
                  <h4>Prêmio Final</h4>
                  <p>
                    O <span>Você no Centro de Tudo 3.0 </span>
                    premiará também os 5 melhores colaboradores posicionados no
                    ranking geral ao final da campanha. Os prêmio serão os
                    seguintes:
                  </p>
                </div>
              </div>
              <div className="in-pm-ctn-grid-ctt-sub-cards">
                <div className="in-pm-ctn-grid-ctt-sub-cards-item">
                  <h5>1º Lugar</h5>
                  <p>
                    Voucher com crédito <br /> no valor de
                    <span> R$600,00</span>.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-cards-item">
                  <h5>2º Lugar</h5>
                  <p>
                    Voucher com crédito <br /> no valor de
                    <span> R$500,00</span>.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-cards-item">
                  <h5>3º Lugar</h5>
                  <p>
                    Voucher com crédito <br /> no valor de
                    <span> R$400,00</span>.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-cards-item">
                  <h5>4º Lugar</h5>
                  <p>
                    Voucher com crédito <br /> no valor de
                    <span> R$300,00</span>.
                  </p>
                </div>
                <div className="in-pm-ctn-grid-ctt-sub-cards-item">
                  <h5>5º Lugar</h5>
                  <p>
                    Voucher com crédito <br /> no valor de
                    <span> R$200,00</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Premiacoes;
