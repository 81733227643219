import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ButtonDefault from '../components/ButtonDefault';
import InputDefault from '../components/InputDefault';

import { resetSenha } from '../services/User';

import '../assets/scss/RecuperarSenha.scss';

const RecuperarSenha = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [erroSenha, setErroSenha] = useState(false);
  const [errors, setErrors] = useState({});
  const { token } = useParams();
  const history = useHistory();

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const redefinirSenha = async (event) => {
    event.preventDefault();
    setErroEmail(false);
    setErroSenha(false);
    try {
      const dados = {
        email,
        password: senha,
        password_confirmation: confirmaSenha,
        token
      };
      await resetSenha(dados);
      setTokenError(false);
      setShowModal(true);
    } catch (error) {
      if (error.response.status === 404) {
        setTokenError(true);
        setShowModal(true);
      } else if (error.response.status === 422) {
        setErrors(error.response.data.errors);
        if (error.response.data.errors?.email?.length > 0) {
          setErroEmail(true);
        }
        if (error.response.data.errors?.password?.length > 0) {
          setErroSenha(true);
        }
      }
    }
  };

  return (
    <section id="in-rcpr">
      <Modal
        centered
        show={showModal}
        onHide={handleClose}
        className={`modal-recupera-senha ${tokenError ? 'error' : ''}`}
        backdropClassName="backdrop-sucesso"
        backdrop="static"
      >
        <Modal.Header>
          <div className="moda-header-close">
            <span onClick={handleClose}>×</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-title">
            <h4>{tokenError ? 'Aviso' : 'Aviso'}</h4>
          </div>
          {tokenError && (
            <p className="modal-body-text">
              Não foi possível alterar a senha. Por favor, solicite a
              recuperação de senha novamente.
            </p>
          )}
          {!tokenError && (
            <p className="modal-body-text">
              Sua senha foi alterada com sucesso.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonDefault
            bgColor={tokenError ? '#E16486' : '#82B477'}
            textColor="#FFFFFF"
            text="Fechar"
            classes="modal-footer-btn"
            onclick={() => history.push('/')}
          />
        </Modal.Footer>
      </Modal>
      <div className="in-rcpr-ctn">
        <div className="in-rcpr-ctn-grid">
          <div className="in-rcpr-ctn-grid-title">
            <h1>Recuperar Senha</h1>
          </div>
          <form className="card-inputs" onSubmit={(e) => redefinirSenha(e)}>
            <InputDefault
              type="email"
              name="email"
              value={email}
              labelPosition="top"
              label="Confirme seu e-mail:"
              onchange={(value) => setEmail(value)}
              erro={erroEmail}
              erroMsg={errors.email?.[0]}
            />
            <InputDefault
              name="senha"
              value={senha}
              type="password"
              label="Nova senha:"
              labelPosition="top"
              onchange={(value) => setSenha(value)}
              erro={erroSenha}
              erroMsg={errors.password?.[0]}
            />
            <InputDefault
              name="senha"
              type="password"
              labelPosition="top"
              value={confirmaSenha}
              label="Confirme sua nova senha:"
              onchange={(value) => setConfirmaSenha(value)}
            />
            <ButtonDefault
              bgColor="#82B477"
              textColor="#FFFFFF"
              text="Redefinir senha"
              classes="modal-footer-btn"
              submit
              disabled={!(senha !== '' && confirmaSenha !== '' && email !== '')}
            />
          </form>
        </div>
      </div>
    </section>
  );
};
export default RecuperarSenha;
